<script setup lang = "ts" >
import { ref } from 'vue'
import axiosClient from '../axiosClient'
import ErrorMessage from '@/components/ErrorMessage.vue';
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3"; 

const errorMessage = ref<string>('')
const eventLog = ref<EventLog[]>([])

export interface EventLog {
  at: string
  userId: number
  orgId: number
  machineId: string
  poolId: number
  value: string
  action: string
  type: string
}

const getEvents = () => {
  axiosClient
    .get<EventLog[]>('/admin/events')
    .then((res) => {
      eventLog.value = res.data
      transformData()
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const forceRefresh = () => {
  axiosClient
    //manually get new auth events as it is fetched in timed intervals
    .get('/admin/refresh-events')
    .then((res) => {
      getEvents()
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const transformData = () => {
  //value field in db might be confusing
  eventLog.value = eventLog.value.map((event) => {
    if (event.type === "payment") {
      event.value = `$${Number(event.value)/100}`;
    }
    if (event.type === "price_log") {
      event.value = `$${event.value}/h`;
    }
    return event;
  });
}

getEvents()

const colDefs = ref([
  { headerName: "Time", field: "at", filter: true  },
  { headerName: "User ID", field: "user_id", filter: true  },
  { headerName: "Organization ID", field: "org_id", filter: true  },
  { headerName: "Machine ID", field: "machine_id", filter: true },
  { headerName: "Pool ID", field: "pool_id", filter: true },
  { headerName: "Value", field: "value", filter: true },
  { headerName: "Action", field: "action", filter: true },
  { headerName: "Type", field: "type", filter: true },
])
</script>
<template>
  <div class="flex justify-between">
  <span class="font-bold text-xl">Event log </span>
  <button class="font-normal m-2 bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded" @click="forceRefresh()">Refresh</button>
  </div>
    <ErrorMessage :message="errorMessage" class="fixed z-10 max-w-lg right-16" />
    <ag-grid-vue
      :rowData="eventLog"
      :columnDefs="colDefs"
      style="height: 90vh"
      class="ag-theme-balham"
      >
    </ag-grid-vue>
  </template>