<script setup lang="ts">
import { ref, computed } from 'vue'
import type { Machine, Location, Pool } from '../types/machine'
import type { Organization } from '../types/account'
import ErrorMessage from '../components/ErrorMessage.vue'
import TableList from '../components/TableList.vue'
import axiosClient from '../axiosClient'

const isLoading = ref<boolean>(true)

const errorMessage = ref<string>('')

const orgs = ref<Organization[]>([])
const locations = ref<Location[]>([])
const pools = ref<any[]>([])
const machines = ref<Machine[]>([])
const availableMachines = computed(() => machines.value.filter((m) => m.status === 1))
let selectedMachineId = ref<string>('')
let newHostName = ref<string>('')

//TODO shows same balance on all orgs if not updated
const balance = ref<number>(0.0)
const totalSpend = ref<number>(0.0)
const usage = ref<number>(0.0)
const timeRemaining = ref<string>('--')

let newKey = {
  name: '',
  value: ''
}

const eventLog = ref<string[]>([])

const getEvents = () => {
  axiosClient
    .get('/admin/events')
    .then((res) => {
      eventLog.value = res.data
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const updateTime = () => {
  nowString.value = new Date().toUTCString()

  for (let i = 0; i < pools.value.length; ++i) {
    const pool = pools.value[i]
    let current_price_idx = -1
    if (pool.price_logs) {
      for (let j = 0; j < pool.price_logs.length; ++j) {
        const log = pool.price_logs[j]

        const now = new Date()
        const log_date = new Date(log.at)

        const diff = now.getTime() - log_date.getTime()
        if (diff > 0 && current_price_idx == -1) {
          current_price_idx = j
          log.price_type = 'current'
        } else if (diff > 0) {
          log.price_type = 'past'
        } else {
          log.price_type = 'future'
        }
      }
    }
  }
}

const nowString = ref<string>('loading...')
setInterval(updateTime, 10000)

const fetchOrgs = () => {
  axiosClient
    .get('/admin/organizations')
    .then((res) => {
      orgs.value = res.data
      isLoading.value = false
      console.log(orgs.value)
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}
fetchOrgs()

const approveOrg = (org: Organization) => {
  if (
    confirm(
      `Are you sure you want to approve ${org.name}? They will now be able to start machines.`
    )
  ) {
    axiosClient
      .post('/admin/org/approve/' + org.id)
      .then(() => {
        fetchOrgs()
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const disableOrg = (org: Organization) => {
  if (
    confirm(
      `Are you sure you want to disable ${org.name}? They will no longer be able to start machines or add funds.`
    )
  ) {
    axiosClient
      .post('/admin/org/disable/' + org.id)
      .then(() => {
        fetchOrgs()
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const blockOrg = (org: Organization) => {
  if (
    confirm(
      `Are you sure you want to disable ${org.name}? They will no longer be able to start machines or add funds.`
    )
  ) {
    axiosClient
      .post('/admin/org/block/' + org.id)
      .then(() => {
        fetchOrgs()
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const deleteOrg = (org: Organization) => {
  if (
    confirm(`Are you sure you want to DELETE the ENTIRE Org? All ${org.name} users will be removed.
    Their machines will be terminated. No history of their existence will remain. This is irreversible.`)
  ) {
    axiosClient
      .delete('/admin/org/' + org.id)
      .then((res) => {
        fetchOrgs()
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const fetchPoolsAndLocations = () => {
  axiosClient
    .get('/admin/pools_locations')
    .then((res) => {
      console.log(res.data)
      locations.value = res.data.locations
      pools.value = res.data.pools

      updateTime()
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}
fetchPoolsAndLocations()

const makeLocVisible = (loc: Location) => {
  if (
    confirm(
      `Are you sure you want to make the location ${loc.name} visible? It will be seen by users.`
    )
  ) {
    axiosClient
      .post('/admin/location/' + loc.id + '/visible')
      .then(() => {
        fetchPoolsAndLocations()
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const makeLocHidden = (loc: Location) => {
  if (
    confirm(`Are you sure you want to make the location ${loc.name} hidden? It will no longer be
    possible to start machines with this location.`)
  ) {
    axiosClient
      .post('/admin/location/' + loc.id + '/hidden')
      .then(() => {
        fetchPoolsAndLocations()
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const makePoolVisible = (pool: Pool) => {
  if (
    confirm(
      `Are you sure you want to make the pool ${pool.name} visible? Users can now start machines from this pool.`
    )
  ) {
    axiosClient
      .post('/admin/pool/' + pool.id + '/visible')
      .then(() => {
        fetchPoolsAndLocations()
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const makePoolHidden = (pool: Pool) => {
  if (
    confirm(`Are you sure you want to make the pool ${pool.name} hidden? It will no longer be
    possible to start machines with this pool.`)
  ) {
    axiosClient
      .post('/admin/pool/' + pool.id + '/hidden')
      .then(() => {
        fetchPoolsAndLocations()
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const deleteLocation = (loc: Location) => {
  if (
    confirm(`Are you sure you want to delete the location (${loc.name}) from DB?
    It needs to removed from MAAS first. It cannot have any machiens attached to it.`)
  ) {
    axiosClient
      .delete('/admin/location/' + loc.id)
      .then(() => {
        fetchPoolsAndLocations()
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const fetchMachines = () => {
  axiosClient
    .get('/admin/machines')
    .then((res) => {
      machines.value = res.data
      if (res.data == null) {
        machines.value = []
      }
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}
fetchMachines()

const deleteUser = (org: Organization, idx: number) => {
  const usr = org.users[idx]
  if (confirm('Are you sure you want to delete user?\n' + usr.name + ' ' + usr.user_id)) {
    axiosClient
      .delete('/admin/user/' + usr.user_id)
      .then(() => {
        org.users.splice(idx, 1)
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const addKey = (name: string, key: string, org_id: number) => {
  axiosClient
    .post('/key/add', {
      name: newKey.name,
      key: newKey.value,
      org_id: org_id
    })
    .then(() => {
      //TODO visual confirmation update
    })
    .catch((error) => {
      //TODO
    })
}
const deleteKey = (idx: number) => {
  if (confirm(`Are you sure you want to delete the key?`)) {
    axiosClient
      .get('/key/delete/' + idx)
      .then((res) => {
        console.log(res)
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const addMachine = (org_id: number, selectedMachineId: string) => {
  console.log(org_id)
  let selectedMachine = machines.value.find((m) => m.system_id === selectedMachineId)
  const confirmText = `Confirm acquire machine ${selectedMachine?.hostname} with the new name ${newHostName.value} to org id ${org_id}`
  if (confirm(confirmText)) {
    if (newHostName.value == '') {
      errorMessage.value = 'Please enter a hostname'
      return
    }
    axiosClient
      .post('/machine/acquire', {
        pool_id: selectedMachine?.pool.id,
        location_id: selectedMachine?.location.id,
        // name: selectedMachine?.hostname,
        name: newHostName.value,
        org_id: org_id
      })
      .then(() => {
        fetchOrgs()
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const syncMachine = (id: string) => {
  axiosClient
    .post('/admin/sync/' + id)
    .then(() => {
      fetchMachines()
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const releaseMachine = (id: string) => {
  if (
    confirm(
      `Are you sure you want to release the machine? It will be removed from the organization and
      will be available for other organizations to use.`
    )
  ) {
    axiosClient
      .post('/admin/machine/' + id + '/release')
      .then(() => {
        fetchMachines()
        alert('DONE')
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const deleteMachine = (m: Machine) => {
  if (
    confirm(
      `Are you sure you want to delete the machine? If you want to hide it,
      allocate the machine through an admin account in MAAS. Thils will change
      the status to hidden. Deleting is only possible if the machine is not owned.
      Do this when the machine is in the 'msising' state. Otherwise it will be added
      again through MAAS sync.`
    )
  ) {
    axiosClient
      .delete('admin/machine/' + m.system_id)
      .then(() => {
        fetchMachines()
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const deposit = (e: Event, org: Organization) => {
  const dat = Object.fromEntries(new FormData(e.target as HTMLFormElement))
  const confirmText = `Confirm deposit for ${org.name} of $${dat.amount}`

  if (confirm(confirmText)) {
    axiosClient
      .post('/admin/deposit', {
        org_id: org.id,
        amount: Number(dat.amount)
      })
      .then(() => {
        fetchOrgs()
        getBalance(org.id)
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const getBalance = (org_id: number) => {
  axiosClient
    .get('/admin/balance/' + org_id)
    .then((res) => {
      balance.value = res.data.balance
      totalSpend.value = res.data.total_spend
      usage.value = res.data.usage
      timeRemaining.value = res.data.time_remaining
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const addPrice = (e: Event, pool: Pool) => {
  const dat = Object.fromEntries(new FormData(e.target as HTMLFormElement))

  let date: Date | null = null
  if (dat.timetype == 'now') {
    date = new Date()
  } else {
    date = new Date(dat.time + ':00Z')
  }

  const confirmText = `
Are you sure you want to change the price?
Pool: ${pool.name}
New Hour Price: ${dat.hr_price}
Time: ${dat.timetype
    } - ${date.getUTCFullYear()}-${date.getUTCMonth()}-${date.getUTCDate()} ${date.getUTCHours()}:${date.getUTCMinutes()} UTC`
  if (confirm(confirmText)) {
    axiosClient
      .post('/admin/add_price', {
        pool_id: pool.id,
        hr_price: Number(dat.hr_price),
        time: date.getTime()
      })
      .then(() => {
        fetchPoolsAndLocations()
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const deletePrice = (id: number) => {
  if (confirm('Are you sure you want to delete the price?')) {
    axiosClient
      .delete('/admin/price/' + id)
      .then(() => {
        fetchPoolsAndLocations()
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}

const poolSortUp = (pool: Pool) => {
  axiosClient
    .post('/admin/pool/' + pool.id + '/up')
    .then(() => {
      fetchPoolsAndLocations()
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const poolSortDown = (pool: Pool) => {
  axiosClient
    .post('/admin/pool/' + pool.id + '/down')
    .then(() => {
      fetchPoolsAndLocations()
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const savePoolMachineType = (pool: Pool) => {
  axiosClient
    .put('/admin/pool/' + pool.id + '/machine_type', pool)
    .then(() => {
      fetchPoolsAndLocations()
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const changePoolDescription = (pool: Pool) => {
  axiosClient
    .put('/admin/pool/' + pool.id + '/description', {
      description: pool.description
    })
    .then(() => {
      fetchPoolsAndLocations()
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const savePoolInfo = (pool: Pool) => {
  axiosClient
    .put('/admin/pool/' + pool.id + '/info', pool)
    .then((res) => {
      fetchPoolsAndLocations()
      alert('Saved.')
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const saveBillingInfo = (org: Organization) => {
  axiosClient
    .post('/admin/save-billing', org)
    .then(() => {
      alert('saved billing information')
      fetchOrgs()
    })
    .catch((err) => (errorMessage.value = err.response.data.message))
}

const sendTestEmail = () => {
  if (
    confirm(
      `An email saying that a new customer has been registered will be sent to support@airon.ai. Are you sure?`
    )
  ) {
    axiosClient
      .post('/admin/test_email')
      .then(() => {
        alert('Email sent')
      })
      .catch((err) => (errorMessage.value = err.response.data.message))
  }
}
</script>

<template>
  <main v-if="!isLoading" data-ls-disabled>
    <h1 class="text-2xl font-bold font-mono">Admin Panel</h1>

    <ErrorMessage :message="errorMessage" class="fixed z-10 max-w-lg right-16" />

    <p>
      <a href="#organizations" class="text-green-500 underline">Organizations</a>
      <br />
      <a href="#locations" class="text-blue-500 underline">Locations</a>
      <br />
      <a href="#pools" class="text-purple-500 underline">Pools</a>
      <br />
      <a href="#machines" class="text-red-500 underline">Machines</a>
      <br />
      <a href="/admin/log" class="text-yellow-500 underline">Logs</a>
    </p>

    <!-- <button @click="" class="bg-blue-500 rounded text-white px-2 py-2 my-2 ">Test button</button> -->
    <div class="mt-8">
      <button type="button" @click="sendTestEmail"
        class="px-2 py-1 bg-gradient-to-b from-gray-100 to-gray-300 hover:border-gray-400 border border-gray-300 text-gray-600 hover:text-gray-900 transition-colors font-mono text-sm">
        Send New Customer Email
      </button>
    </div>
    <br>

    <!-- ORGS -->
    <h2 id="organizations" class="text-xl font-bold mt-8">Organizations</h2>
    <ul class="mt-2 bg-white outline outline-green-500 rounded-lg divide-y-4 divide-green-500">
      <li v-for="org in orgs" :key="org.id" class="p-2">
        <details class="flex">
          <summary :id="'org-' + org.id">
            {{ org.id }} -
            <span class="font-bold">{{ org.name }}</span>
            - {{ org.status_name }}
          </summary>

          <!-- ACTIONS -->
          <div class="mx-4">
            <h3 class="font-bold">Actions</h3>

            <button type="button" class="text-sm bg-green-500 text-white underline px-4 rounded-full"
              v-if="org.status_name != 'Active'" @click="approveOrg(org)">
              Activate org
            </button>
            <button type="button" class="text-sm bg-red-500 text-white underline px-4 rounded-full"
              v-if="org.status_name != 'Pending Approval'" @click="disableOrg(org)">
              Disable org
            </button>
            <button type="button" class="text-sm bg-red-500 text-white underline px-4 rounded-full"
              v-if="org.status_name != 'Blocked'" @click="blockOrg(org)">
              Block org
            </button>
            <button type="button" class="text-sm bg-red-500 text-white underline px-4 rounded-full"
              @click="deleteOrg(org)">
              delete entire org
            </button>
          </div>

          <!-- $$$ -->
          <div class="mt-2 mx-4">
            <h3 class="font-bold">Money:</h3>
            Total Deposit: ${{ org.total_deposit / 100 }}
            <br />
            <form class="text-sm" @submit.prevent="(e) => deposit(e, org)">
              Deposit:
              <input name="amount" type="number" step="0.01" />
              <button type="submit" class="ml-2 bg-green-500 text-white underline px-4 rounded-full">
                deposit
              </button>
              <br />
              <details @click="getBalance(org.id)">
                <summary>Balance info</summary>
                <table>
                  <tr>
                    <td>Balance:</td>
                    <td :class="{ 'bg-red-500 text-white': balance < 0 }">${{ balance }}</td>
                  </tr>
                  <tr>
                    <td>Total Spend:</td>
                    <td>${{ totalSpend }}</td>
                  </tr>
                  <tr>
                    <td>Usage:</td>
                    <td>${{ usage }}/h</td>
                  </tr>
                  <tr>
                    <td>Time Remaining:</td>
                    <td>{{ timeRemaining }}</td>
                  </tr>
                </table>
                <button type="button" class="bg-blue-500 text-white underline px-4 rounded-full"
                  @click="getBalance(org.id)">
                  Refresh
                </button>
              </details>
            </form>

            <details>
              <summary>Billing Information</summary>
              <div v-if="org.billing">
                <p>
                  <label class="font-semibold">Company name:</label>
                  <input type="text" class="p-1 h-5 border-airon-medium rounded-md ml-2"
                    v-model="org.billing.company_name" />
                </p>
                <p>
                  <label class="font-semibold">Tax:</label>
                  {{ org.billing.tax_id_type }}
                  {{ org.billing.tax_id }}
                </p>
                <p>
                  <label class="font-semibold">Name:</label>
                  <input type="text" class="p-1 h-5 border-airon-medium rounded-md ml-2"
                    v-model="org.billing.first_name" />
                  <input type="text" class="p-1 h-5 border-airon-medium rounded-md ml-2"
                    v-model="org.billing.last_name" />
                </p>
                <p>
                  <label class="font-semibold">Country:</label>
                  <input type="text" class="p-1 h-5 border-airon-medium rounded-md ml-2"
                    v-model="org.billing.country" />
                  <br />
                  <label class="font-semibold">Address:</label>
                  <input type="text" class="p-1 h-5 border-airon-medium rounded-md ml-2"
                    v-model="org.billing.street_address" />
                  <br />
                  <label class="font-semibold">City:</label>
                  <input type="text" class="p-1 h-5 border-airon-medium rounded-md ml-2" v-model="org.billing.city" />
                  <br />
                  <label class="font-semibold">State:</label>
                  <input type="text" class="p-1 h-5 border-airon-medium rounded-md ml-2" v-model="org.billing.state" />
                  <br />
                  <label class="font-semibold">Zip:</label>
                  <input type="text" class="p-1 h-5 border-airon-medium rounded-md ml-2" v-model="org.billing.zip" />
                </p>
                <button type="button" @click="saveBillingInfo(org)"
                  class="bg-green-500 rounded-full px-2 text-sm text-white">
                  Save billing info
                </button>
              </div>
              <p v-else>Organization has not added billing information.</p>
            </details>
          </div>

          <!-- MACHINES -->
          <div class="mt-2 mx-4">
            <details>

              <summary>Machines</summary>
              <form>
                Assign new machine:
                <!-- TODO picks the right pool not the exact individual machine -->
                <select v-model="selectedMachineId" id="options">
                  <option v-for="m in availableMachines" :key="m.system_id" :value="m.system_id">
                    {{ m.location.name }} - {{ m.pool.name }} - {{ m.system_id }} - {{ m.hostname }}
                  </option>
                </select>
                <br>
                Hostname: <input v-model="newHostName" type="text">
                <button type="button" @click="addMachine(org.id, selectedMachineId)"
                  class="bg-green-500 text-sm text-white underline px-4 rounded-full">
                  Add machine to org
                </button>
              </form>
              <!-- {{ machines[0] }} -->
              <ul class="border rounded-md text-sm">
                <li v-for="m in org.machines" :key="org.id + m.system_id" class="p-2">
                  <a :href="'#' + m.system_id" class="underline text-blue-500">{{ m.system_id }}</a>
                  -
                  <span class="font-medium">{{ m.hostname }}</span>
                </li>
              </ul>
            </details>
          </div>

          <!-- KEYS -->
          <details class="mt-2 mx-4">
            <summary>SSH Keys:</summary>
            <form>
              <label>
                Name:
                <input v-model="newKey.name" type="text" name="nameInput"
                  class="p-1 h-5 border-airon-medium rounded-md ml-2" />
              </label>
              <br />
              <label>
                Key:
                <textarea v-model="newKey.value" class="p-1 h-5 border-airon-medium rounded-md ml-2" name="keyInput" />
              </label>
              <br>
              <button type="button" @click="addKey(newKey.name, newKey.value, org.id)"
                class="bg-green-500 text-sm text-white underline px-4 rounded-full">
                Add new key
              </button>
            </form>
            <ul class="border rounded-md text-sm">
              <li v-for="k in org.ssh_keys" :key="org.id + k.id" class="p-1">
                {{ k.id }}
                "
                <span class="font-bold">{{ k.name }}</span>
                " at: {{ k.created_at }}
                <button type="button" class="bg-red-500 text-white underline px-4 rounded-full"
                  @click="deleteKey(k.id)">
                  delete
                </button>
                <br />
                <textarea class="text-xs p-0 w-full">{{ k.key }}</textarea>

              </li>
            </ul>
          </details>

          <!-- USERS -->
          <div v-if="org.users?.length > 0" class="mt-2 mx-4">
            <h3 class="font-bold">Users:</h3>
            <span class="font-medium text-sm">Invite Code:</span>
            <span class="text-sm bg-gray-200 rounded-md ml-2 px-1 py-0.5 w-fit">
              {{ org.invite_code }}
            </span>
            <ul class="border rounded-md text-sm mt-4">
              <li v-for="(usr, i) in org.users" :key="i" class="p-2 even:bg-gray-100">
                ID: {{ usr.user_id }}
                <br />
                name:
                <span class="font-medium">{{ usr.name }}</span>
                (email: {{ usr.email }}) role: {{ usr.role_name }}
                <br />
                Actions:
                <button type="button" class="bg-red-500 text-white underline px-4 rounded-full"
                  @click="deleteUser(org, i)">
                  delete
                </button>
              </li>
            </ul>
          </div>

          <div v-if="org.user_logs?.length > 0" class="mt-2 mx-4 bg-white">
            <h3 class="font-bold">User Logs:</h3>

            <div class="mx-4 max-h-64 overflow-y-auto">
              <TableList>
                <thead class="sticky top-0">
                  <tr>
                    <th class="bg-stone-50">ID</th>
                    <th class="bg-stone-50">Org ID</th>
                    <th class="bg-stone-50">User ID</th>
                    <th class="bg-stone-50">Action</th>
                    <th class="bg-stone-50">Machine ID</th>
                    <th class="bg-stone-50">Pool ID</th>
                    <th class="bg-stone-50">At</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="log in org.user_logs" :key="log.id">
                    <td>{{ log.id }}</td>
                    <td>{{ log.org_id }}</td>
                    <td>{{ log.user_id }}</td>
                    <td>{{ log.action }}</td>
                    <td>{{ log.machine_id }}</td>
                    <td>{{ log.pool_id }}</td>
                    <td>{{ log.at }}</td>
                  </tr>
                </tbody>
              </TableList>
            </div>
          </div>
        </details>
      </li>
    </ul>

    <!-- LOCATIONS -->
    <h2 id="locations" class="text-xl font-bold mt-8">Locations</h2>
    <span class="text-gray-400 font-medium">(from MAAS AZs)</span>
    <ul class="mt-2 bg-white rounded-lg outline outline-blue-500 divide-y-4 divide-blue-500">
      <li v-for="loc in locations" :key="loc.id" class="p-4">
        <details :id="'loc-' + loc.id">
          <summary>
            {{ loc.id }} -
            <span class="font-bold">{{ loc.name }}</span>
            - {{ loc.status_name }}
          </summary>

          <div class="text-sm space-x-2">
            <h3 class="font-bold">Actions</h3>

            <button type="button" class="bg-blue-500 text-white underline px-4 rounded-full"
              @click="makeLocVisible(loc)">
              make visible
            </button>
            <button type="button" class="bg-blue-500 text-white underline px-4 rounded-full"
              @click="makeLocHidden(loc)">
              make hidden
            </button>
            <button type="button" class="bg-red-500 text-white underline px-4 rounded-full"
              @click="deleteLocation(loc)">
              delete
            </button>
          </div>

          <div v-if="loc.machines?.length > 0">
            <h3 class="font-medium">Machines ({{ loc.machines.length }}):</h3>
            <ul class="text-sm max-h-32 overflow-auto ring-1 ring-purple-500 rounded-md">
              <li v-for="m in loc.machines" :key="`${loc.id} ${m.system_id}`" class="odd:bg-gray-100 px-4 py-2">
                <a :href="'#' + m.system_id" class="underline text-blue-500">{{ m.system_id }}</a>
                -
                <span class="font-medium">{{ m.hostname }}</span>

                <div v-if="m.org_id" class="inline ml-4 bg-gray-200 p-2">
                  Owned By:
                  <a class="text-blue-500 hover:underline" :href="'#org-' + m.org_id">
                    Org ({{ m.org_id }})
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </details>
      </li>
    </ul>

    <!-- POOLS -->
    <h2 id="pools" class="text-xl font-bold mt-8">Pools</h2>
    <span class="text-gray-400 font-medium">(from MAAS Pools)</span>
    <ul class="mt-2 bg-white rounded-lg outline outline-purple-500 divide-y divide-purple-500">
      <li v-for="pool in pools" :key="pool.id" class="p-4">
        <details :id="'pool-' + pool.id">
          <summary>
            {{ pool.id }} -
            <span class="font-bold">{{ pool.name }}</span>
            - {{ pool.status_name }}
          </summary>

          <div>
            Machine type: {{ pool.machine_type }}
            <select v-model="pool.machine_type" class="px-1 py-0.5 w-fit">
              <option :value="0">Unknown (0)</option>
              <option :value="1">GPU (1)</option>
              <option :value="2">General Compute (2)</option>
            </select>
            <button class="text-white bg-green-500 px-4 ml-2 rounded-full" @click="savePoolMachineType(pool)">
              save
            </button>
          </div>

          <div>
            <h3 class="font-medium">Actions:</h3>
            <div class="text-sm space-x-2">
              <button type="button" class="bg-blue-500 text-white underline px-4 rounded-full"
                @click="makePoolVisible(pool)">
                make visible
              </button>
              <button type="button" class="bg-blue-500 text-white underline px-4 rounded-full"
                @click="makePoolHidden(pool)">
                make hidden
              </button>

              sort:
              <button type="button" class="bg-blue-500 text-white underline px-4 rounded-full"
                @click="poolSortUp(pool)">
                up
              </button>

              <button type="button" class="bg-blue-500 text-white underline px-4 rounded-full"
                @click="poolSortDown(pool)">
                down
              </button>
            </div>
          </div>
          <div>
            <h3 class="font-medium">Description (not used):</h3>
            <textarea v-model="pool.description" class="p-1 rounded-md text-sm w-full min-h-32"></textarea>
            <br />
            <button type="button" class="bg-green-500 text-sm text-white underline px-4 rounded-full"
              @click="changePoolDescription(pool)">
              change description
            </button>
          </div>
          <div class="p-2 mt-4 border border-airon-dark rounded-md w-fit">
            <label>
              GPU eg. B200
              <input v-model="pool.gpu" type="text" class="py-2 h-6 mt-2" />
            </label>
            <br />
            <label>
              Type eg. HGX
              <input v-model="pool.type" type="text" class="py-2 h-6 mt-2" />
            </label>
            <br />
            <label>
              CPU eg. Intel
              <input v-model="pool.cpu" type="text" class="py-2 h-6 mt-2" />
            </label>
            <br />
            <label>
              RAM eg. 2048 GB
              <input v-model="pool.ram" type="text" class="py-2 h-6 mt-2" />
            </label>
            <br />
            <label>
              Storage eg. 4x3.2 TB
              <input v-model="pool.storage" type="text" class="py-2 h-6 mt-2" />
            </label>
            <br />
            <button type="button" @click="savePoolInfo(pool)" class="mt-2 px-3 bg-green-500 text-white rounded-full">
              save info
            </button>
          </div>
          <div v-if="pool.machines?.length > 0">
            <h3 class="font-medium">Machines:</h3>
            <ul class="text-sm max-h-32 overflow-auto ring-1 ring-purple-500 rounded-md">
              <li v-for="m in pool.machines" :key="`${pool.id} ${m.system_id}`" class="odd:bg-gray-100 px-4 py-2">
                <a :href="'#' + m.system_id" class="underline text-blue-500">{{ m.system_id }}</a>
                -
                <span class="font-medium">{{ m.hostname }}</span>

                <div v-if="m.org_id" class="inline ml-4 bg-gray-200 p-2">
                  Owned By:
                  <a class="text-blue-500 hover:underline" :href="'#org-' + m.org_id">
                    Org ({{ m.org_id }})
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div class="flex mt-2 gap-4">
            <!-- ADD PRICE -->
            <form @submit.prevent="(e) => {
    addPrice(e, pool)
  }
    ">
              <h3 class="font-medium">Add Price</h3>
              New Hour Price:
              <input min="0" step="0.05" type="number" name="hr_price" class="border bg-gray-100 px-2 py-1 w-24" />
              <fieldset class="p-2 border">
                <legend>Time</legend>
                <label>
                  <input name="timetype" type="radio" value="now" />
                  Now
                </label>
                <br />
                <label>
                  <input name="timetype" type="radio" value="specify" />
                  Specify (UTC)
                </label>
                <input type="datetime-local" name="time" class="p-1 border" />
              </fieldset>
              <button type="submit"
                class="w-full bg-gray-100 py-2 mt-2 rounded-full shadow-sm hover:shadow-md hover:bg-gray-200">
                Add
              </button>
            </form>
            <div class="flex-grow">
              <h3>Price Logs:</h3>
              <span class="text-xs bg-gray-100 px-1 py-1">now: {{ nowString }}</span>
              <div class="max-h-96 overflow-y-auto">
                <TableList class="border">
                  <thead class="sticky top-0 bg-white">
                    <tr>
                      <th>ID</th>
                      <th>Hr Price</th>
                      <th>Time (UTC)</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="log in pool.price_logs" :key="log.id" :class="{
    'bg-green-100': log.price_type == 'current',
    'bg-blue-100': log.price_type == 'future'
  }">
                      <td>{{ log.id }}</td>
                      <td>${{ log.hr_price }}</td>
                      <td>{{ log.at }}</td>
                      <td>
                        <button v-if="log.price_type == 'future'" type="button"
                          class="text-sm bg-red-500 text-white underline px-4 rounded-full"
                          @click="deletePrice(log.id)">
                          delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </TableList>
              </div>
            </div>
          </div>
        </details>
      </li>
    </ul>

    <h2 id="machines" class="text-xl font-bold mt-8">{{ machines.length }} Machines</h2>
    <ul class="mt-2 bg-white rounded-lg outline outline-red-500 divide-y divide-red-500 text-sm">
      <li v-for="m in machines" :key="m.system_id" class="p-2 even:bg-gray-100">
        <details :id="m.system_id">
          <summary>
            {{ m.system_id }} -
            <span class="font-medium">{{ m.hostname }}</span>
            - {{ m.status_name }}
            <div v-if="m.organization" class="inline ml-4 bg-gray-200 p-2">
              Owned By:
              <a class="text-blue-500 hover:underline" :href="'#org-' + m.organization.id">
                {{ m.organization.id }} -
                <span class="font-bold">{{ m.organization.name }}</span>
              </a>
            </div>
          </summary>
          <div class="grid grid-cols-2">
            <div>
              Hostname: {{ m.hostname }}
              <br />
              Given Name: {{ m.name }}
              <br />
              Status: {{ m.status }} "{{ m.status_name }}"
              <br />
              Project ID: {{ m.project_id }}
              <br />
              Location:
              <a class="text-blue-500 hover:underline" :href="'#loc-' + m.location.id">
                {{ m.location.id }} - {{ m.location.name }}
              </a>
              <br />
              Pool:
              <a class="text-blue-500 hover:underline" :href="'#pool-' + m.pool.id">
                {{ m.pool.id }} - {{ m.pool.name }}
              </a>

              <br />
              Actions:
              <button type="button" class="bg-red-500 text-white underline px-4 rounded-full"
                @click="releaseMachine(m.system_id)">
                release
              </button>
              <button type="button" class="ml-2 bg-red-500 text-white underline px-4 rounded-full"
                @click="deleteMachine(m)">
                delete
              </button>
            </div>
            <div class="p-2 bg-gray-100 rounded-md">
              <span class="font-medium text-gray-400">
                From MAAS (Use MAAS UI for additional info)
              </span>
              <br />
              IP: {{ m.ip_addresses[0] }}
              <br />
              CPU: {{ m.cpu_speed / 1000 }} GHz x {{ m.cpu_count }} cores
              <br />
              OS: {{ m.osystem }}
              <br />
              Power State: {{ m.power_state }}
              <br />
              RAM: {{ m.ram / (1024 * 1024 * 1024) }} GiB
              <br />
              Disk Storage: {{ Math.floor(m.storage / (1000 * 1000 * 1000)) }} GB
              <br />
              Actions:
              <button type="button" class="bg-green-500 text-white underline px-4 rounded-full"
                @click="syncMachine(m.system_id)">
                sync
              </button>
            </div>
          </div>
        </details>
      </li>
    </ul>

  </main>
</template>
