<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import axiosClient from '../axiosClient'
import FlagIcon from '../components/FlagIcon.vue'
import type { SSHKey } from '../types/machine.d.ts'
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline'
import AddKeyPopoutPanel from '../components/AddKeyPopoutPanel.vue'
import { InformationCircleIcon } from '@heroicons/vue/20/solid'
import { useGlobalStore } from '../stores/global'
import ErrorMessage from '../components/ErrorMessage.vue'
import { useAuth0 } from '@auth0/auth0-vue'


/*
mail på user invite
mail vid fail deploy
*/

interface Pool {
  id: number
  name: string
  avail_machine_ids: string[]
  current_hr_price: number
  machine_type: number
}

interface Location {
  id: number
  name: string
  avail_machine_ids: string[]
  num_machines_in_selected_pool: number
  is_selected: boolean
}

const errorMessage = ref<string>('')

const name = ref<string>('')
const canStart = ref<boolean>(false)
const pools = ref<Pool[]>([])
const GPUpools = computed(() => pools.value.filter((p) => p.machine_type == 1))
const CPUPools = computed(() => pools.value.filter((p) => p.machine_type == 2))
const locations = ref<Location[]>([])
const keys = ref<SSHKey[]>()

const selectedLocation = ref<Location>()
const selectedPool = ref<Pool>()

const balance = ref<number>()
const requiredBalance = computed(() => {
  if (selectedPool.value) {
    return selectedPool.value.current_hr_price * 6
  }
  return 0
})

const { fetchNotifications } = useGlobalStore()

const { user } = useAuth0()

const fetchOverview = () => {
  axiosClient
    .get('machine/overview')
    .then((res) => {
      const { data } = res
      canStart.value = data.can_start
      name.value = data.hostname_example
      locations.value = data.locations
      pools.value = data.pools

      pools.value = pools.value.filter((p) => p.current_hr_price > 0.001)

      if (locations.value.length == 1) {
        selectedLocation.value = locations.value[0]
      }

      const fake_available_emails: string[] = [];

      if (user.value && user.value.email) {
        if (fake_available_emails.includes(user.value.email)) {
          for (let i = 0; i < pools.value.length; i++) {
            pools.value[i].avail_machine_ids = ["fake"]
          }
        }
      }

      const url_pool_id = new URLSearchParams(window.location.search).get('p')
      if (url_pool_id) {
        for (let i = 0; i < pools.value.length; i++) {
          if (pools.value[i].id.toString() == url_pool_id) {
            selectedPool.value = pools.value[i]
            break
          }
        }
        // calculateOverlap()
      }
    })
    .catch((res) => (errorMessage.value = res.response.data.message))

  axiosClient
    .get('/monitor/usage')
    .then((response) => {
      balance.value = response.data.balance
    })
    .catch((res) => (errorMessage.value = res.response.data.message))
}
fetchOverview()

const addKeyOpen = ref<boolean>(false)

const fetchKeys = () => {
  axiosClient
    .get('key/list')
    .then((response) => {
      const { data } = response

      keys.value = data
    })
    .catch((res) => (errorMessage.value = res.response.data.message))
}
fetchKeys()

const keyAdded = () => {
  fetchKeys()
  fetchNotifications()
}

interface TimeType {
  name: string
  hours: number
}

const estimateTimeValue = ref<number>(1.0)

const estimateTimeTypes: TimeType[] = [
  {
    name: 'Hour(s)',
    hours: 1
  },
  {
    name: 'Day(s)',
    hours: 24
  },
  {
    name: 'Month(s)',
    hours: 730
  }
]

const timeType = ref<TimeType>(estimateTimeTypes[0])
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const estimatedTotalCost = computed(() => {
  return selectedPool.value?.current_hr_price! * estimateTimeValue.value * timeType.value?.hours
})

const locationOrigin = window.location.origin

const router = useRouter()

const startCompute = () => {
  const requestData = {
    pool_id: selectedPool.value?.id,
    location_id: selectedLocation.value?.id,
    name: name.value
  }

  axiosClient
    .post('/machine/acquire', requestData)
    .then(() => {
      router.push('/compute')
    })
    .catch((res) => (errorMessage.value = res.response.data.message))
}

const hostnameIncorrectMessage = ref<string>('')
const getIncorrectNameMessage = () => {
  const str = name.value.trim()
  const len = str.length

  if (len == 0) {
    return 'Name cannot be empty.'
  }
  if (len > 32) {
    return 'Name too long. Name can be up to 32 characters long.'
  }

  for (let i = 0; i < len; i++) {
    let c = str[i]
    const isLowerCase = 'a' <= c && c <= 'z'
    const isUpperCase = 'A' <= c && c <= 'Z'
    const isHyphen = c == '-'
    const isNumber = '0' <= c && c <= '9'
    if (!isLowerCase && !isUpperCase && !isHyphen && !isNumber) {
      if (c == ' ') {
        c = 'space'
      }
      return 'Invalid character -> ' + c
    }
  }

  if (str[0] == '-' || str[len - 1] == '-') {
    return 'A hyphen in the beginning or end of a hostname is forbidden.'
  }

  return ''
}

const hostnameChanged = () => {
  hostnameIncorrectMessage.value = getIncorrectNameMessage()
}
</script>

<template>
  <div>
    <div class="text-center text-4xl">
      <h2 class="font-semibold">Start Compute Instance</h2>
    </div>

    <div class="mt-16 max-w-3xl mx-auto">
      <!-- Availabitiy Zone -->
      <div class="flex gap-4">
        <div
          class="hidden sm:flex items-center justify-center w-8 h-8 aspect-square border border-airon-border rounded-full"
        >
          1
        </div>
        <div>
          <h3 class="font-medium text-xl sm:text-2xl">
            <span class="sm:hidden">1.</span>
            Choose an Availability Zone
          </h3>
          <p class="mt-2">Geographical location in which your compute instance will be created.</p>

          <div class="mt-4 flex gap-4">
            <label
              v-for="l in locations"
              class="flex gap-2 border border-airon-border w-48 p-4 rounded-md hover:cursor-pointer has-[:checked]:border-airon-dark hover:border-airon-medium transition-colors"
            >
              <input
                type="radio"
                class="hidden"
                name="location"
                :value="l"
                v-model="selectedLocation"
              />
              <FlagIcon class="w-8" country="SE" />
              <span class="font-medium">
                {{ l.name }}
              </span>
            </label>
          </div>
        </div>
      </div>

      <div class="flex gap-4 mt-16">
        <div
          class="hidden sm:flex items-center justify-center w-8 h-8 aspect-square border border-airon-border rounded-full"
        >
          2
        </div>
        <div>
          <div>
            <h3 class="font-medium text-xl sm:text-2xl">
              <span class="sm:hidden">2.</span>
              Choose Billing Method
            </h3>
            <p class="mt-2">Select how you want to be billed for your compute instance.</p>
          </div>

          <div class="mt-4 flex gap-4">
            <label
              class="flex items-center gap-2 border border-airon-border w-48 p-4 rounded-md hover:cursor-pointer has-[:checked]:border-airon-dark hover:border-airon-medium transition-colors"
            >
              <input type="radio" class="" name="billing" checked />
              <span class="font-medium">Hourly on-demand</span>
            </label>

            <label
              class="flex items-center gap-2 border border-gray-300 bg-gray-100 text-gray-400 min-w-48 p-4 rounded-md hover:cursor-not-allowed"
            >
              <input type="radio" class="bg-gray-100 border-gray-300" name="billing" disabled />
              <span class="font-medium">Monthly commitment</span>
            </label>
          </div>
        </div>
      </div>

      <div class="flex flex-grow gap-4 mt-16">
        <div
          class="hidden sm:flex items-center justify-center w-8 h-8 aspect-square border border-airon-border rounded-full"
        >
          3
        </div>
        <div class="w-full">
          <h3 class="font-medium text-xl sm:text-2xl">
            <span class="sm:hidden">3.</span>
            Choose Server
          </h3>
          <p class="mt-2">Select the type of instance you would like to launch.</p>

          <p class="font-medium mt-4">GPU Instances</p>
          <div class="mt-4 w-full space-y-2 columns-1 sm:columns-2">
            <button v-for="p in GPUpools" class="block text-left w-full">
              <label
                class="block break-inside-avoid rounded-md has-[:disabled]:bg-gray-100 has-[:disabled]:text-gray-500 has-[:disabled]:hover:cursor-not-allowed group has-[:checked]:border-airon-dark has-[:checked]:bg-gray-200 has-[:checked]:text-gray-800 p-4 hover:bg-gray-700 hover:text-white hover:cursor-pointer transition-colors duration-150 border"
              >
                <input
                  type="radio"
                  class="peer hidden"
                  name="machine"
                  :value="p"
                  v-model="selectedPool"
                  :disabled="p.avail_machine_ids == null || p.avail_machine_ids.length == 0"
                />
                <span>{{ p.name }}</span>
                <!-- <span v-if="p.machine_type == 2" class="transition-all bg-gray-200 px-1 rounded-sm ml-2 outline outline-1 outline-gray-300 group-hover:outline-gray-500 group-hover:bg-gray-800 group-hover:text-gray-300 peer-checked:bg-gray-300 peer-checked:outline-gray-400 peer-checked:text-gray-900">General Compute</span> -->
                <br />
                <span
                  class="group-hover:peer-disabled:text-gray-500 group-hover:text-gray-300 peer-checked:text-gray-500 text-gray-500 transition-colors"
                >
                  {{ formatter.format(p.current_hr_price) }}/h
                </span>
                <span
                  class="hidden opacity-0 peer-checked:inline peer-checked:opacity-100 text-gray-500 transition-opacity"
                >
                  (Selected)
                </span>
                <span
                  class="border-l border-gray-300 px-1"
                  v-if="p.avail_machine_ids == null || p.avail_machine_ids.length == 0"
                >
                  no capacity available
                </span>
              </label>
            </button>
          </div>
              <p class="font-medium mt-4">General Compute Instances</p>
          <div class="mt-4 w-full space-y-2 columns-1 sm:columns-2">
              <button v-for="p in CPUPools" class="block text-left w-full">
                <label
                  class="block break-inside-avoid rounded-md has-[:disabled]:bg-gray-100 has-[:disabled]:text-gray-500 has-[:disabled]:hover:cursor-not-allowed group has-[:checked]:border-airon-dark has-[:checked]:bg-gray-200 has-[:checked]:text-gray-800 p-4 hover:bg-gray-700 hover:text-white hover:cursor-pointer transition-colors duration-150 border"
                >
                  <input
                    type="radio"
                    class="peer hidden"
                    name="machine"
                    :value="p"
                    v-model="selectedPool"
                    :disabled="p.avail_machine_ids == null || p.avail_machine_ids.length == 0"
                  />
                  <span>{{ p.name }}</span>
                  <br />
                  <span
                    class="group-hover:peer-disabled:text-gray-500 group-hover:text-gray-300 peer-checked:text-gray-500 text-gray-500 transition-colors"
                  >
                    {{ formatter.format(p.current_hr_price) }}/h
                  </span>
                  <span
                    class="hidden opacity-0 peer-checked:inline peer-checked:opacity-100 text-gray-500 transition-opacity"
                  >
                    (Selected)
                  </span>
                  <span
                    class="border-l border-gray-300 px-1"
                    v-if="p.avail_machine_ids == null || p.avail_machine_ids.length == 0"
                  >
                    no capacity available
                  </span>
                </label>
              </button>
            </div>
        </div>
      </div>

      <div class="flex gap-4 mt-16">
        <div
          class="hidden sm:flex items-center justify-center w-8 h-8 aspect-square border border-airon-border rounded-full"
        >
          4
        </div>
        <div>
          <h3 class="font-medium text-xl sm:text-2xl">
            <span class="sm:hidden">4.</span>
            Choose Image
          </h3>
          <p class="mt-2">Select which operating system to install on this instance.</p>

          <div class="mt-4 flex gap-4">
            <label
              class="flex flex-grow items-center gap-2 border border-airon-border p-4 rounded-md hover:cursor-pointer has-[:checked]:border-airon-dark hover:border-airon-medium transition-colors"
            >
              <input type="radio" class="hidden" checked />
              <img src="/ubuntu.svg" class="h-10 aspect-square" />
              <div class="flex-grow">
                <span class="font-medium text-lg">Ubuntu</span>
                <br />
                <span class="text-sm text-gray-500 whitespace-nowrap">
                  v22.04 LTS (Jammy Jellyfish)
                </span>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div class="flex gap-4 mt-16">
        <div
          class="hidden sm:flex items-center justify-center w-8 h-8 aspect-square border border-airon-border rounded-full"
        >
          5
        </div>
        <div>
          <h3 class="font-medium text-xl sm:text-2xl">
            <span class="sm:hidden">5.</span>
            Instance Name
          </h3>
          <p class="mt-2">
            Give your deployed instance a hostname. The dashboard listing name can be changed later.
            Your hostname is fixed.
          </p>

          <div class="mt-4">
            <label class="w-full">
              <input
                type="text"
                name="name"
                :class="{
                  'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6': true,
                  'ring-red-500': hostnameIncorrectMessage.length != 0
                }"
                placeholder="bright-bengal"
                @input="hostnameChanged"
                v-model="name"
              />
            </label>
            <p class="pt-2 text-sm text-red-500">{{ hostnameIncorrectMessage }}</p>
            <p class="pt-2 text-sm text-gray-500">
              Up to 32 lower- and uppercase letters A through Z are allowed, including hyphens.
              Numbers are also allowed. E.g. growling-grizzly or FAST-FISH-5.
            </p>
          </div>
        </div>
      </div>

      <div class="flex max-w-full gap-4 mt-16">
        <div
          class="hidden sm:flex items-center justify-center w-8 h-8 aspect-square border border-airon-border rounded-full"
        >
          6
        </div>
        <div class="grow overflow-hidden">
          <h3 class="font-medium text-xl sm:text-2xl">
            <span class="sm:hidden">6.</span>
            SSH Keys
          </h3>
          <p class="mt-2">
            Keys used for remote access. All keys attached to your organization will be deployed. Go
            <span class="inline-flex items-baseline text-blue-500 underline">
              <a :href="locationOrigin + '/keys'" target="_blank">here</a>
              <ArrowTopRightOnSquareIcon class="self-center w-4 h-4 ml-1" />
            </span>
            to manage your ssh keys further.
          </p>

          <div class="mt-4 w-full">
            <ul class="divide-y divide-airon-border border border-gray-200 rounded-md">
              <li v-if="keys == null || keys.length == 0" class="px-6 py-4 text-gray-600">
                Press the button below to add your first SSH key. You need to have at least 1 key
                before starting an instance.
              </li>
              <li v-for="k in keys" class="w-full px-4 py-2">
                {{ k.name }}
                <span class="text-nowrap overflow-hidden w-full block">{{ k.key }}</span>
              </li>
            </ul>
            <button
              type="button"
              @click="addKeyOpen = true"
              class="mt-6 transition-colors rounded-md bg-gray-800 w-full px-2.5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
            >
              Add New Key
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-grow gap-4 mt-16">
        <div
          class="hidden sm:flex items-center justify-center w-8 h-8 aspect-square border border-airon-border rounded-full"
        >
          7
        </div>
        <div class="w-full">
          <h3 class="font-medium text-xl sm:text-2xl">
            <span class="sm:hidden">7.</span>
            Estimated Cost
          </h3>
          <div class="mt-4 w-full">
            <div
              v-if="selectedPool == null"
              class="flex items-center w-full px-5 py-4 bg-white border border-airon-border rounded-md mb-4 text-gray-600"
            >
              <InformationCircleIcon class="inline w-5 h-5 text-black mr-2" />
              Please select a server type before proceeding.
            </div>

            <div class="flex justify-end">
              <input
                type="number"
                min="1"
                step="1"
                class="m-0 border-0 border-y border-l border-airon-border w-16 py-2 px-3 rounded-l-md"
                v-model="estimateTimeValue"
              />
              <select
                name="timeType"
                autocomplete=""
                class="py-2 border-1 border-airon-border rounded-r-md font-medium"
                v-model="timeType"
              >
                <option v-for="e in estimateTimeTypes" :value="e">
                  {{ e.name }}
                </option>
              </select>
            </div>
            <div class="flex gap-4">
              <div class="grow divide-y divide-airon-border rounded-md">
                <div class="flex justify-between py-3">
                  <p class="hidden sm:block">Availability Zone</p>
                  <p class="font-medium">{{ selectedLocation?.name }}</p>
                </div>
                <div class="flex justify-between py-3">
                  <p class="hidden sm:block">Image</p>
                  <p class="flex items-center font-medium">
                    <img src="/ubuntu.svg" class="w-5 h-5 inline mr-1" />
                    Ubuntu
                  </p>
                </div>
                <div class="flex justify-between py-3">
                  <p class="hidden sm:block">Server</p>
                  <p class="font-medium">{{ selectedPool?.name || '---' }}</p>
                </div>
              </div>

              <div class="w-64 text-right divide-y divide-airon-border">
                <div class="py-3">$0.00</div>
                <div class="py-3">$0.00</div>
                <div class="py-3" v-if="selectedPool">${{ selectedPool?.current_hr_price }}/h</div>
                <div class="py-3" v-else>---</div>
                <div class="py-3 flex justify-between">
                  <div>
                    <span class="whitespace-nowrap mr-4">
                      For {{ estimateTimeValue }} {{ timeType.name.toLowerCase() }}
                    </span>
                  </div>
                  <p v-if="estimatedTotalCost">{{ formatter.format(estimatedTotalCost) }}</p>
                  <p v-else>---</p>
                </div>
              </div>
            </div>

            <div
              v-if="balance! < requiredBalance"
              class="mt-4 px-6 py-4 border rounded-md border-red-500 text-red-500 bg-red-50"
            >
              Before starting an instance, your balance must be equivalent to covering 6 hours or
              more of the selected server's operating time.
              <div class="border-t border-red-500 mt-4 pt-4 text-red-700 divide-x divide-red-500">
                <span class="pr-2">Your balance: {{ formatter.format(balance!) }}</span>
                <span class="px-2">Required balance: {{ formatter.format(requiredBalance) }}</span>
                <span class="pl-2 inline-flex items-baseline text-blue-500 underline">
                  <a
                    :href="locationOrigin + '/billing'"
                    target="_blank"
                    class="underline text-blue-500"
                  >
                    <span>Make deposit</span>
                  </a>
                  <ArrowTopRightOnSquareIcon class="self-center w-4 h-4 ml-1" />
                </span>
              </div>
            </div>

            <div
              v-if="!canStart"
              class="mt-4 px-6 py-4 border border-airon-border rounded-md text-gray-600"
            >
              You need to complete setup before starting a new compute instance. The notification
              cards at the top of this page will instruct you on how to proceed.
            </div>

            <div
              v-if="hostnameIncorrectMessage.length != 0"
              class="mt-4 px-6 py-4 border border-airon-border rounded-md text-gray-600"
            >
              The provided hostname is invalid. Please change to another name.
            </div>

            <ErrorMessage :message="errorMessage" class="mt-4 border border-red-500" />

            <button
              type="button"
              @click="startCompute"
              class="mt-6 transition-colors rounded-md bg-gray-800 w-full px-2.5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-400"
              :disabled="
                !canStart ||
                balance! < requiredBalance ||
                selectedPool == null ||
                hostnameIncorrectMessage.length != 0
              "
            >
              Start New Compute Instance
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <AddKeyPopoutPanel :open="addKeyOpen" @close="addKeyOpen = false" @submit="keyAdded" />
</template>
